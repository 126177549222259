.footer {
  padding: 38px 0;
  position: relative;
  background: #FFE8E8;
  @include media("<=810px") {
    overflow: visible;
    padding: 30px 0;
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 gap: 30px;
color: #300001;
  @include media("<=650px") {
    
  }
}

.footer__row {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  &:first-of-type {
    padding-bottom: 20px;
    border-bottom: 1px #300001 solid;
  }
    @include media("<=810px") {
       flex-direction: column;
    }
}

.footer__nav {
  column-gap: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include media("<=810px") {
    justify-content: flex-start;
  }
}

.copyright {

color: #300001;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  @include media("<=810px") {
    font-size: 12px;
  }
}

.footer__logo {
color: #300001;
  text-decoration: none;
  font-weight: 600;
}

.footer__link {
  color: #300001;
    text-decoration: none;
}

.footer__social {
  display: flex;
  gap: 8px;
}