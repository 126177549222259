.about {
  overflow-x: clip;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
    
  }
  &-light {
    border-top: 2px solid rgba(255, 255, 255, 0.60);
      background: #FFE8E8;
      color: rgba(0, 0, 0, 0.80);
  }
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  gap: 20px;

  @include media("<=810px") {
     flex-direction: column;
     align-items: center;
     row-gap: 30px;
  }
  @include media("<=500px") {
   
  }
}

.about__content {
  max-width: 717px;
  width: 100%;
  display: flex;
    flex-direction: column;
    row-gap: 15px;
    line-height: 1.4;
    
}

.about__text {
  border-top: 2px solid rgba(255, 255, 255, 0.60);
  padding: 30px 0 0;
  font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    display: flex;
    font-size: 18px;
      flex-direction: column;
      row-gap: 15px;
    @include media("<=430px") {
      font-size: 14px;
    }
}

.about__title {
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
}

.about__image {
  max-width: 335px;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.about__pic {
  max-width: 317px;
  width: 100%;
      mix-blend-mode: luminosity;
  img {
    width: 100%;
    display: block;
  }
}

.about__item {
  border-radius: 20px;
    background: rgba(23, 156, 233, 0.20);
    padding: 20px;
    display: flex;
      flex-direction: column;
      row-gap: 15px;
    span {
      display: block;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
    }
}









