.hero {
  position: relative;
  padding: 80px 0;

  @include media("<=1080px") {

  }

  @include media("<=810px") {
    padding-top: 40px;
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  padding-top: 40px;
  width: 100%;
  display: flex;
border-top: 2px solid rgba(255, 255, 255, 0.60);
  align-items: center;
  position: relative;
  justify-content: space-between;
  gap: 20px;

  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: center;
   row-gap: 30px;
  }

  @include media("<=430px") {}
}

.hero__content {
 position: relative;
  width: 100%;
  max-width: 717px;
  line-height: 1.5;
  @include media("<=810px") {
    flex-direction: column;
    display: flex;
  }
}

.hero__title {
  font-size: 64px;
  font-weight: 600;
  
  position: relative;
  z-index: 3;
  margin-bottom: 40px;
  line-height: 1.1;
  @include media("<=1280px") {
   
    
  }

  @include media("<=1080px") {
    font-size: 56px;

  }

  @include media("<=810px") {
   
    
  }
    @include media("<=550px") {
      font-size: 40px;
  
    }

  @include media("<=430px") {
    font-size: 38px;

  }


  @include media("<=350px") {
    
  }
}

.hero__subtitle {
 
 
  font-weight: 400;
  font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.hero__text {

  font-size: 14px;

  @include media("<=1280px") {
    padding: 30px;
  }
  @include media("<=430px") {
    padding: 20px;
  }
}

.button {
 
  background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 12px;
    width: 100%;
   color: #000;
    border: none;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
  
    

  @include media("<=810px") {
    
  }
 
  &:hover {
    background: #2748ec;
  }
  &-consent {
    max-width: 198px;
  }
}



.hero__image {
  flex: 0 1 100%;
  max-width: 339px;
  width: 100%;
  position: relative;
    overflow: hidden;
    mix-blend-mode: luminosity;
  @include media("<=810px") {
   
  }

  @include media("<=430px") {
    
  }

  img {
    width: 100%;
    display: block;
    @include media("<=1280px") {
      
    }
  }
}

.arrow {
  max-width: 51px;
  width: 100%;
  margin-left: auto;
  &-hero {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media("<=810px") {
      position: relative;
      align-self: flex-end;
    }
  }
}
