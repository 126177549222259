.features {
  padding: 60px 0;

@include media("<=810px") {
    padding-bottom: 80px;
}
}

.features__inner {
    padding-top: 40px;
    display: flex;
    gap: 24px;
  
    justify-content: space-between;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
        @include media("<=430px") {
            
        }
}

.features__subtitle {
    padding-top: 20px;
    font-size: 15px;
}

.features__item {
    border-right: 1px solid rgba(255, 255, 255, 0.80);
        border-left: 1px solid rgba(255, 255, 255, 0.80);
    display: flex;
    flex-direction: column;
    gap: 15px;
    line-height: 1.2;
    padding: 0 24px;
    flex: 0 1 31%;
    width: 100%;
    font-size: 18px;
   
    @include media("<=1080px") {
      
    }
    
        @include media("<=810px") {
            flex: 0 1 100%;
            
        }
        @include media("<=430px") {
            font-size: 16px;
            width: 100%;
        }
        
}

.features__title{
    
  
    @include media("<=600px") {
       
    }
    span {
        font-size: 24px;
            font-weight: 700;
            display: block;
            margin-bottom: 16px;
            text-transform: uppercase;
    }
}

.features__icon {
    width: 100%;
    img {
        width: 100%;
    }
}