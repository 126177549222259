.info {
    padding: 60px 0;
    background: #FFE8E8;
    color: #000;
    @include media("<=810px") {
  
    }
    
}

.info__inner {
    display: flex;

    justify-content: space-between;
    gap: 24px;
    padding-top: 40px;
    @include media("<=810px") {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       gap: 20px;
    }
}


.info__item {
    border-top: 1px solid rgba(0, 0, 0, 0.80);
        flex: 0 1 32%;
        display: flex;
        padding-top: 24px;
        flex-direction: column;
        gap: 16px;
        line-height: 1.2;
        @include media("<=810px") {
            flex: 0 1 100%;
            width: 100%;
        }
}

.info__icon {
    width: 70px;
    height: 70px;
    display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        border-radius: 10px;
            background: #179CE9;
            color: #FFF;
}

.info__title {
    font-size: 24px;
    font-weight: 600;
 
    text-transform: uppercase;
}


.info__text {
    
        font-size: 16px;
        font-weight: 400;
  
            @include media("<=1080px") {
                row-gap: 14px;
                font-size: 14px;
            }
        @include media("<=810px") {
          font-size: 16px;
        }
            
        

}

.info__subtext {
    padding-top: 30px;
    font-weight: 500;
}

