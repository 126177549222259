.blog {
    padding: 80px 0;
}

.blog__inner {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
}

.blog__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 0 1 31%;
    border-radius: 16px;
        background: #FFF;
        padding: 32px;
        line-height: 1.2;
        @include media("<=810px") {
            flex: 0 1 100%;
            max-width: 450px;
        }
        span {
            font-weight: 600;
            text-transform: uppercase;
        }
}

.blog__image {
    width: 100%;
    img {
        width: 100%;
    }
}

